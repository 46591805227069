<template>
  <div class="about">
    <h2 class="text-center">
      Gracias por comunicarte con <br />
      Gutierrez y Asociados.
    </h2>
    <p class="pa-4 text-center">
      Tu información ha sido recibida exitosamente.<br />Nuestro equipo se
      estará comunicando contigo lo más pronto posible.
    </p>
    <v-btn href="/" color="primary" width="200px"> Inicio</v-btn>
  </div>
</template>
<script>
export default {
  name: "tanks",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.about {
  // background-color: #d60303;
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-image: url("../assets/trama-04.svg");
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
